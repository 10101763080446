import React from "react";
import { Route, Redirect } from "react-router-dom";
import App from "../components/App";
import About from "../components/About";
import Header from "../components/Header";
import Login from "../LoginPage/LoginPage"
import Users from "../Pages/User/Users"
import Organizations from "../Pages/Organization/Organizations"
import { authenticationService } from '../_services/authentication.service';

const ReactRouter = () => {
    return (
        <React.Fragment>
            <Header />
            <PrivateRoute exact path="/portal" component={App} />
            <PrivateRoute  path="/portal/about" component={About} />
            <PrivateRoute exact path = "/portal/manage/organizations" component = {Organizations} />
            <PrivateRoute exact path = "/portal/manage/users" component={Users} />
            <Route path="/portal/login" component={Login} />
        </React.Fragment>
    );
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        console.log("Current User: ", currentUser);
        if (!currentUser) {// not logged in so redirect to login page with the return url
            return <Redirect to={{pathname: '/portal/login', state: {from: props.location}}} />
}

// authorised so return component
return <Component {...props}/>
}}/>)
export default ReactRouter;