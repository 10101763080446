import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
}
from 'reactstrap';
//import { Link } from "react-router-dom";

import { authenticationService } from '../_services/authentication.service';
import { history } from '../_helpers/history';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  logout() {
        authenticationService.logout();
        history.push('/portal/login');
  }
    
  render() {
    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/portal/">
          
          <img className="logo" src="../../images/ControlTrack/ControlTrack-logo-horizontal.png" alt="ControlTrack Logo" height="90" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/portal/about/">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/portal">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/portal/login">
                  <div onClick={this.logout}>Logout</div>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Manage
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink href="/portal/manage/organizations">organizations</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/portal/manage/users">users</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header
