import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Col, Container} from 'reactstrap';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.css';

import { authenticationService } from '../_services/authentication.service';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) {
            this.props.history.push('/portal');
        }
    }

    render() {
        return (
            <Container>
                <div className="form">
                    <small style={{display: 'flex',  justifyContent:'center'}} className="alert alert-info">You are running this application in <b style={{padding: '0px 2px 0px 2px'}}>{process.env.NODE_ENV}</b> mode</small>
                    <h2>Login</h2>
                    <Formik
                        initialValues={{
                            deID: '',
                            password: ''
                        }}
                        validationSchema={Yup.object().shape({
                            deID: Yup.string().required('deID is required'),
                            password: Yup.string().required('Password is required')
                        })}
                        onSubmit={({ deID, password }, { setStatus, setSubmitting }) => {
                            setStatus();
                            authenticationService.login1(deID, password)
                                .then(
                                    user => {
                                        const { from } = this.props.location.state || { from: { pathname: "/portal" } };
                                        this.props.history.push(from);
                                    },
                                    error => {
                                        setSubmitting(false);
                                        error ="Invalid User or User does not have the supported permmissions to login."
                                        setStatus(error);
                                        console.log('In error part R');
                                    }
                                );
                        }}
                        render={({ errors, status, touched, isSubmitting }) => (
                            <Form>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="deID">deID</label>
                                        <Field name="deID" type="text" className={'form-control' + (errors.deID && touched.deID ? ' is-invalid' : '')} />
                                        <ErrorMessage name="deID" component="div" className="invalid-feedback" />
                                    </div> 
                                </Col>
                                <div className = "form-group" >
                                    <Col>
                                        <label htmlFor="password">Password</label> 
                                            <Field name = "password"
                                            type = "password"
                                            className = { 'form-control' + (errors.password && touched.password ? ' is-invalid' : '') }
                                            /> 
                                        <ErrorMessage name = "password" component = "div" className = "invalid-feedback" />
                                    </Col>
                                </div>
                                <div className = "form-group" >
                                    <Col>
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button> {
                                            isSubmitting &&
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="" />
                                        }
                                    </Col>
                                </div> {
                                    status &&
                                    <div className={'alert alert-danger'}><Col>{status}</Col></div>
                                } 
                            </Form>
                        )
                    }/> 
                </div>
            </Container>
        )
    }
}

export default LoginPage
