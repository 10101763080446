import { BehaviorSubject } from 'rxjs';

//import config from 'config';
//import history from '../_helpers/history'
//import { withRouter } from 'react-router-dom'
import { handleResponse } from '../_helpers/handle-response';
import { config } from '../_helpers/Constants'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login1,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};
function login1(deID, password) {
    debugger;
    const requestOptions = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deID, password })
    };
    var url = config.url.API_URL
    console.log (url);
    return fetch(url + 'user/login1', requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            return user;
        }
        );
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

export default authenticationService
