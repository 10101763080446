import React, {Component} from 'react'
import { handleResponse } from '../../_helpers/handle-response';
import { config } from '../../_helpers/Constants'
import { authenticationService } from '../../_services/authentication.service'

import { Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledAlert, Col } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            unmountOnClose: true,
            currentUser: [null],
            isLoading: true,
            message: '',
            organizations: {}
        };

        this.toggle = this.toggle.bind(this);
        this.changeUnmountOnClose = this.changeUnmountOnClose.bind(this);
        this.createUser = this.createUser.bind(this);
        this.getOrg = this.getOrg.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    changeUnmountOnClose(e) {
        let value = e.target.value;
        this.setState({ unmountOnClose: JSON.parse(value) });
    }
    
    componentDidMount(){
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
        //this.getUsers();
    }
    
    
    createUser(deID, password, role, organization, token){
        console.log("Make request to create org here")
        var bearer = token
        const requestOptions = {
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': bearer },
            body: JSON.stringify({ organizationID :organization, username: deID, password: password, role: role})
        };
        var url = config.url.API_URL
        return fetch(url +'admin/create/user', requestOptions)
            .then(handleResponse)
            .then((data) => {
                //this.setState({ isLoading: false, downlines: data.response });
                console.log("DATA STORED");
                return data
             })
    } 
    getOrg(){
        this.setState({isLoading: true})
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
        
        //Get All Organizations
        console.log("Make request to create org here", localStorage.getItem('currentUser'))
        var json = JSON.parse(localStorage.getItem('currentUser'))
        var bearer = json.token
        const requestOptions = {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': bearer },
            //body: JSON.stringify({ orgName:organizationName })
        };
        var url = config.url.API_URL
        fetch(url +'admin/organizations', requestOptions)
            .then(handleResponse)
            .then(data => {
                const organizatons = data
                console.log("Organizations: ", organizatons)
                this.setState({ organizations: data, isLoading: false})
            })
    }

    render() {
        
        // const orgListSelect = this.state.isLoading? "Loading...": this.props.organizations.org.map((option, index) => 
        //                                            <option key={index} 
        //                                              value={JSON.stringify(option)}>
        //                                              {option.name}
        //                                            </option>
        //                                          )
        
        return (
            <Container>
                <br />
                <div className="text-center">
                    <Button className="btn btn-primary" style={{backgroundColor: '#0079dc'}} onClick={this.toggle}>{this.props.buttonLabel}</Button>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} unmountOnClose={this.state.unmountOnClose}>
                    <ModalHeader toggle={this.toggle}>Creact User</ModalHeader>
                    <ModalBody>
                        <Formik
                            initialValues={{
                                deID: '',
                                password: '',
                                confirmPassword: '',
                                role: '',
                                organization: '',
                            }}
                            validationSchema={Yup.object().shape({
                                deID: Yup.string().required('De-identified ID is required'),
                                password: Yup.string().required('Password is required'),
                                confirmPassword: Yup.string()
                                    .required('Password confirmation is required')
                                    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                                role: Yup.string().required('Role is required'),
                                organization: Yup.string().required('The name of the organization is required')
                            })}
                            onSubmit={({ deID, password, confirmPassword, role, organization }, { setStatus, setSubmitting, resetForm }) => {
                                setStatus();
                                setSubmitting(true);
                                this.createUser(deID, password, role, organization, this.state.currentUser.token).then(
                                    data => {
                                        setSubmitting(false)
                                        resetForm();
                                        setStatus("success")
                                    },
                                    error => {
                                        setSubmitting(false)
                                        console.log("error in submitting: ", error)
                                        setStatus(error)
                                    }
                                )
                            }}
                            render={({ errors, status, touched, isSubmitting }) => (
                                <Form>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="deID">De-identified ID</label>
                                            <Field name="deID" type="text" className={'form-control' + (errors.deID ? ' is-invalid' : '')} />
                                            <ErrorMessage name="deID" component="div" className="invalid-feedback" />
                                            
                                            <label htmlFor="password">Password</label>
                                            <Field name="password" type="text" className={'form-control' + (errors.password ? ' is-invalid' : '')} />
                                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                            
                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                            <Field name="confirmPassword" type="text" className={'form-control' + (errors.confirmPassword ? ' is-invalid' : '')} />
                                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                            
                                            <label htmlFor="role">Role</label>
                                            <Field name="role" type="text" component="select" className={'form-control' + (errors.role ? ' is-invalid' : '')}>
                                                <option>Select one of the Options</option>
                                                <option>admin</option>
                                                <option>support</option>
                                                <option>pharmacy</option>
                                            </Field>
                                            <ErrorMessage name="role" component="div" className="invalid-feedback" />
                                            
                                            <label htmlFor="organization">Organization Name</label>
                                            <Field name="organization" type="text" component="select" className={'form-control' + (errors.organization ? ' is-invalid' : '')}>
                                                <option>Select one of the options</option>
                                                {this.props.organizations.org.map((option, index) =>
                                                   <option key={index} value={option.id}>
                                                    {option.name}
                                                   </option>
                                                 )}
                                            </Field>
                                            <ErrorMessage name="organization" component="div" className="invalid-feedback" />
                                            
                                            {
                                                status !== 'success' && status &&
                                                //<div className={'alert alert-danger'}><Col>{status}</Col></div>
                                                <UncontrolledAlert color="danger">
                                                    {status}
                                                </UncontrolledAlert>
                                            } 
                                            {
                                                status === 'success' && status &&
                                                //<div className={'alert alert-success'}><Col>{status}</Col></div>
                                                <UncontrolledAlert color="success">
                                                    {status}
                                                </UncontrolledAlert>
                                            } 
                                        </div>
                                    </Col>
                                    <div className = "form-group" >
                                        <Col className="text-center">
                                            <button type="submit" className="btn btn-primary" style={{backgroundColor: '#0079dc'}} disabled={isSubmitting}>Create</button> {
                                                isSubmitting &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="" />
                                            }
                                            
                                        </Col>
                                    </div> 
                                </Form>
                            )
                        }/>
                    </ModalBody>
                    <ModalFooter>
                        {/*<Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}*/}
                        <Button color="secondary" onClick={this.toggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}
export default UserForm