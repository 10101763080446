import { authenticationService } from '../_services/authentication.service';
// import { Redirect } from 'react-router-dom'
// import React from 'react'

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            console.log([401, 403].indexOf(response.status))
            if ([401, 403].indexOf(response.status) !== -1) {
                console.log("Error 401 or 403 Recieved")
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
            }

            const error = (data && data.message) || response.status;
            console.log("Error in Handle Response: ", error)
            return Promise.reject(error);
        }

        return data;
    });
}

export default handleResponse
