import React, { Component } from "react"
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Col, Container, Table, UncontrolledAlert} from 'reactstrap'
import * as Yup from 'yup'
import 'bootstrap/dist/css/bootstrap.css'
import OrgList from "../Organization/OrgList"

import { authenticationService } from '../../_services/authentication.service'
import { handleResponse } from '../../_helpers/handle-response';
import { config } from '../../_helpers/Constants'

class Organizations extends Component {
    constructor(props) {super(props);
        this.state = {
            currentUser: [null],
            isLoading: true,
            message: '',
            organizations: {},
            accounts: {}
        };
        this.createOrg = this.createOrg.bind(this)
        this.getOrg = this.getOrg.bind(this)
        this.getUsers = this.getUsers.bind(this);
    }
    
    componentDidMount() {
        this.getOrg();
        this.getUsers();
    }
    
    getUsers(){
        this.setState({isLoading: true})
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
        
        //Get All Users
        console.log("Make request to create org here", localStorage.getItem('currentUser'))
        var json = JSON.parse(localStorage.getItem('currentUser'))
        var bearer = json.token
        const requestOptions = {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': bearer }
        };
        var url = config.url.API_URL
        fetch(url +'admin/users', requestOptions)
            .then(handleResponse)
            .then(data => {
                const users = data
                console.log("Users: ", users)
                this.setState({ accounts: data, isLoading: false})
                console.log(users);
            })
    }

    getOrg(){
        this.setState({isLoading: true})
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
        
        //Get All Organizations
        console.log("Make request to create org here", localStorage.getItem('currentUser'))
        var json = JSON.parse(localStorage.getItem('currentUser'))
        console.log('RRRRRRRRRRR');
        console.log(json);
        var bearer = json.token;
        const requestOptions = {
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Authorization': bearer}
            //body: JSON.stringify({ orgName:organizationName })
        };
        console.log(requestOptions);
        var url = config.url.API_URL
        fetch(url +'admin/organizations', requestOptions)
            .then(handleResponse)
            .then(data => {const organizatons = data
                console.log("Organizations: ", organizatons)
                this.setState({ organizations: data, isLoading: false})
            })
    }
    
    createOrg(organizationName, token){
        console.log("Make request to create org here")
        var bearer = token
        const requestOptions = {
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': bearer },
            body: JSON.stringify({ orgName:organizationName })
        };
        var url = config.url.API_URL
        return fetch(url +'admin/create/organization', requestOptions)
            .then(handleResponse)
            .then((data) => {
                //this.setState({ isLoading: false, downlines: data.response });
                console.log("DATA STORED");
                this.getOrg()
                return data
             })
    }
    
    render() {
        debugger;
        if (this.state.isLoading) {
            return "Loading...";
        }
        if (this.state.organizations.org) {
            const ListComponent =this.state.organizations.org.map(item => <OrgList key={item.id} org={item}/>)
            return (
                <div>
                    <br/>
                    <Container>
                        <div className="form">
                            <h4>Create a New Organization:</h4>
                            <br/>
                            <Formik
                                initialValues={{
                                    organizationName: ''
                                }}
                                validationSchema={Yup.object().shape({
                                    organizationName: Yup.string().required('The name of the organization is required')
                                })}
                                onSubmit={({ organizationName }, { setStatus, setSubmitting, resetForm }) => {
                                    setStatus();
                                    setSubmitting(true);
                                    this.createOrg(organizationName, this.state.currentUser.token).then(
                                        data => {
                                            setSubmitting(false)
                                            resetForm();
                                            setStatus("success")
                                        },
                                        error => {
                                            setSubmitting(false)
                                            console.log("error in submitting: ", error)
                                            setStatus(error)
                                        }
                                    )
                                }}
                                render={({ errors, status, touched, isSubmitting }) => (
                                    <Form>
                                        <Col>
                                            <div className="form-group">
                                                <label htmlFor="organizationName">Organization Name</label>
                                                <Field name="organizationName" type="text" className={'form-control' + (errors.organizationName ? ' is-invalid' : '')} />
                                                <ErrorMessage name="organizationName" component="div" className="invalid-feedback" />
                                                
                                                {
                                                    status !== 'success' && status &&
                                                    //<div className={'alert alert-danger'}><Col>{status}</Col></div>
                                                    <UncontrolledAlert color="danger">
                                                        {status}
                                                    </UncontrolledAlert>
                                                } 
                                                {
                                                    status === 'success' && status &&
                                                    //<div className={'alert alert-success'}><Col>{status}</Col></div>
                                                    <UncontrolledAlert color="success">
                                                        {status}
                                                    </UncontrolledAlert>
                                                } 
                                            </div>
                                        </Col>
                                        <div className = "form-group" >
                                            <Col>
                                                <button type="submit" className="btn btn-primary" style={{backgroundColor: '#0079dc'}} disabled={isSubmitting}>Create</button> {
                                                    isSubmitting &&
                                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="" />
                                                }
                                            </Col>
                                        </div> 
                                    </Form>
                                )
                            }/> 
                        </div>
                    </Container>
                    <Container>
                        <br/><br/>
                        <h4>Existing Organiziations: </h4>
                        <Table responsive>
                            <thead>
                              <tr>
                                <th>Org Name</th>
                                <th>Org Code</th>
                              </tr>
                            </thead>
                                {ListComponent} 
                          </Table>
                    </Container>
                </div>
            );
        }
        else 
        {
            return ("There is no organisation exist");
            
        }
    }
}

export default Organizations;