import React, {Component} from 'react'
import { handleResponse } from '../../_helpers/handle-response';
import { config } from '../../_helpers/Constants'
import { authenticationService } from '../../_services/authentication.service'
import {Container, Table, Button, Row, Col} from 'reactstrap'

import UserForm from '../User/UserForm'
import UserList from '../User/UserList'

class Users extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            currentUser: [null],
            isLoading: true,
            message: '',
            accounts: {},
            organizations: {}
        };
        
        this.getUsers = this.getUsers.bind(this);
        this.getOrg = this.getOrg.bind(this);
    }
    
    componentDidMount(){
        this.getUsers();
        this.getOrg();
    }
    
    getUsers(){
        this.setState({isLoading: true})
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
        //Get All Users
        console.log("Make request to create org here", localStorage.getItem('currentUser'))
        var json = JSON.parse(localStorage.getItem('currentUser'))
        var bearer = json.token
        const requestOptions = {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': bearer }
        };
        var url = config.url.API_URL
        fetch(url +'admin/users', requestOptions)
            .then(handleResponse)
            .then(data => {
                const users = data
                console.log("Users: ", users)
                this.setState({ accounts: data, isLoading: false})
                console.log(users);
            })
    }
    
    getOrg(){
        this.setState({isLoading: true})
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
        
        //Get All Organizations
        console.log("Make request to create org here", localStorage.getItem('currentUser'))
        var json = JSON.parse(localStorage.getItem('currentUser'))
        var bearer = json.token
        const requestOptions = {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': bearer },
            //body: JSON.stringify({ orgName:organizationName })
        };
        var url = config.url.API_URL
        fetch(url +'admin/organizations', requestOptions)
            .then(handleResponse)
            .then(data => {
                const organizatons = data
                console.log("Organizations: ", organizatons)
                this.setState({ organizations: data, isLoading: false})
            })
    }
    
    render(){
        debugger;
        if (this.state.isLoading) {
            return "Loading...";
        }
        if (this.state.accounts.users) {
            const  ListComponent =this.state.accounts.users.map(item=><UserList key={item.id}user={item}/>)
        return(
            <div>
                <Container>
                    <h4>Create User:</h4>
                    <UserForm buttonLabel="Create User" organizations={this.state.organizations}/>
                </Container>
                <Container>
                    <br/><br/>
                    <Row>
                        <Col xs="6">
                            <h4>Existing Users: </h4>
                        </Col>
                        <Col xs="6">
                            <Button size="sm"className="btn btn-primary float-right" style={{backgroundColor: '#9fa1a4'}} onClick={this.getUsers}>refresh</Button>
                        </Col>
                    </Row>
                    <Table responsive>
                        {<thead>
                          <tr>
                            <th>User ID</th>
                            <th>User Name</th>
                            <th>Role</th>
                            <th>Org ID</th>
                            <th>Created By</th>
                          </tr>
                        </thead>}
                        {ListComponent}
                      </Table>
                </Container>
            </div>
        )
                        }
        else 
        {
            return ("There is no user exist");
            
        }
    }
}

export default Users