import React, { Component } from 'react';
// import { Col, Container} from 'reactstrap';
import { Container} from 'reactstrap';
import { authenticationService } from '../_services/authentication.service';
import { history } from '../_helpers/history';

//import { PrivateRoute } from '../router/router.js';


class App extends Component {
  constructor(props) {
        super(props);

        this.state = {
            currentUser: [null]
        };
    }

    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }

    logout() {
        authenticationService.logout();
        history.push('/portal/login');
    }

    render() {
        // eslint-disable-next-line no-lone-blocks
        {/*const { currentUser } = this.state;*/}
        return (
            <Container>
                <div>
                  <h1>This is the app</h1>
                  <small style={{display: 'flex',  justifyContent:'center'}} className="alert alert-info">You are running this application in <b style={{padding: '0px 2px 0px 2px'}}>{process.env.NODE_ENV}</b> mode</small>
                  <br />
                  <small>{this.state.currentUser.token}</small>
                </div>
            </Container>
        );
    }
}

export default App;
