import React, { Component} from "react"
import {Button} from 'reactstrap'
import { handleResponse } from '../../_helpers/handle-response';
import { config } from '../../_helpers/Constants'
import { authenticationService } from '../../_services/authentication.service'

class UserList extends Component {
    
    constructor(props) {
        super(props);
    
        this.handleDelete = this.handleDelete.bind(this)
    }
    
    handleDelete(){
        console.log("User you are trying to delete: ", this.props.user.deID)
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
        
        //Get All Users
        console.log("Make request to create org here", localStorage.getItem('currentUser'))
        var json = JSON.parse(localStorage.getItem('currentUser'))
        var bearer = json.token
        const requestOptions = {
            credentials: 'include',
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': bearer },
            body: JSON.stringify({ deID: this.props.user.deID})
        };
        var url = config.url.API_URL
        fetch(url +'admin/users', requestOptions)
            .then(handleResponse)
            .then(data => {
                window.location.reload();
            })
    }
    
    render(){
        return (
            <tbody>{<tr key = {this.props.id}><td>{this.props.user.id}</td><td>{this.props.user.deID}</td><td>{this.props.user.role}</td><td>{this.props.user.org}</td><td>{this.props.user.createdBy}</td><td><Button size='sm' onClick={this.handleDelete} color='danger'>Delete</Button></td></tr>}</tbody>
        )
    }
}

export default UserList