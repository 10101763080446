import 'bootstrap/dist/css/bootstrap.css';
import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactRouter from './router/router';
// setup fake backend
//import { configureFakeBackend } from './_helpers/fake-backend';
//configureFakeBackend();
ReactDOM.render(<Router>
        <ReactRouter/>
    </Router>,document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.unregister();
