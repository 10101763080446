import React from "react"

function OrgList(props) {
    console.log("Props: ", props)
    return (
        <tbody>{
              <tr key = {props.id}>
                  <td>{props.org.id}</td>
                  <td>{props.org.name}</td>
              </tr>
         }</tbody>
    )
}

export default OrgList