// Constants.js
const prod = {
 url: {
  API_URL: 'https://controltrack.app/'
  //API_URL: 'http://103.39.132.19:1337/'
 }
};
const dev = {
 url: {
  //API_URL: 'https://controltrack.app/'
 // API_URL: 'https://emptyscotch.com/'
 ///API_URL: 'http://103.39.132.19:1337/'
  //API_URL: 'https://controltrack.app/'
   API_URL: 'http://localhost:1337/'
 }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;